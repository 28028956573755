/* Hero Section Styling */
.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 90vh;
  padding: 8px 20px;
  background: linear-gradient(135deg, #6c63ff, #3b3f92);
  text-align: left;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  margin-left: 60px;
}

.hero h1 {
  font-size: 3.5rem;
  color: var(--text-color);
  line-height: 1.2;
}

.hero .highlight {
  color: var(--highlight-color);
}

.hero .tagline {
  margin: 20px 0;
  font-size: 1.2rem;
  color: #f0f0f0;
}

.hero-buttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.btn-primary {
  padding: 12px 24px;
  background: var(--highlight-color);
  border-radius: 50px;
  color: white;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background: white;
  color: var(--highlight-color);
  transform: scale(1.1);
}

.btn-secondary {
  padding: 12px 24px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid var(--highlight-color);
  border-radius: 50px;
  color: white;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background: var(--highlight-color);
  color: white;
  transform: scale(1.1);
}

/* Image Styling */
.hero-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px; /* To add spacing between the text and image */
}

.hero-image {
  max-width: 40%;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

/* Hover Effect on Image */
.hero-image:hover {
  transform: scale(1.05); /* Slight zoom-in effect */
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding-top: 50px;
    
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero-visual {
    margin-top: 20px;
  }

  .hero-image {
    max-width: 70%; /* Ensure the image doesn't overflow on smaller screens */
    margin-bottom: 180px;
  }

  .hero-content {
    margin-left: 0px; 
  }
}


/* Social Icons */
.social-icons {
  margin-top: 20px;
  display: flex;
  
  gap: 15px; /* Space between icons */
}

.social-icon {
  color: #333;
  font-size: 1.5rem; /* Larger size for better visibility */
  transition: color 0.3s;
}

.social-icon:hover {
  color: #0077b5; /* Customize hover color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px; /* Reduce spacing for smaller screens */
  }

  .social-icon {
    font-size: 1.5rem; /* Slightly smaller icons for phones */
  }
}
