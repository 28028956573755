.projects {
  padding: 80px 20px;
  text-align: center;
  background: linear-gradient(135deg, #6c63ff, #3b3f92);
}

.projects h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white; 
}

.projects-description {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #f9f9f9; 
}

.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.project-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-10px); 
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.project-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-card h3 {
  font-size: 1.6rem;
  margin: 20px 0;
  color: #3b3f92; 
}

.project-card p {
  font-size: 1rem;
  color: #666;
  padding: 0 20px;
}

.project-card .btn {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background: linear-gradient(135deg, #6c63ff, #3b3f92); 
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.project-card .btn:hover {
  background-color: #ffcc00; 
}


@media (max-width: 768px) {
  .projects-container {
    flex-direction: column;
    align-items: center;
  }

  .project-card {
    width: 80%;
    margin-bottom: 20px;
  }
}
