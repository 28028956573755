.contact {
    text-align: center;
    padding: 60px 20px;
  }
  
  .contact h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .contact form {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .contact input {
    width: 300px;
    padding: 10px 15px;
    border-radius: 50px;
    border: 1px solid var(--glass-border);
    background: var(--glass-bg);
    color: var(--text-color);
    font-size: 1rem;
  }
  
  .contact input::placeholder {
    color: #ccc;
  }
  
  .contact button {
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 1rem;
  }
  