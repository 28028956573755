:root {
  --glass-bg: rgba(255, 255, 255, 0.15);
  --glass-border: rgba(255, 255, 255, 0.3);
  --highlight-color: #6c63ff;
  --text-color: #fff;
  --font-primary: 'Poppins', sans-serif;
}

body {
  font-family: var(--font-primary);
  margin: 0;
  background: linear-gradient(135deg, #6c63ff, #3b3f92);
  color: var(--text-color);
  overflow-x: hidden;
}

h1, h2, p {
  margin: 0;
  line-height: 1.5;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.btn-primary, .btn-secondary {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background: var(--highlight-color);
  border-radius: 50px;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease;
}

.btn-primary:hover, .btn-secondary:hover {
  transform: scale(1.1);
}

section {
  padding: 60px 0;
}
